import { Button, Theme } from '@mui/material';
import { styled } from '@mui/styles';

interface CustomButtonProps {
  theme: Theme;
  active?: boolean;
  gray?: boolean;
}

const CustomButton = styled(Button)(
  ({ theme, active, gray }: CustomButtonProps) => {
    let color = theme.colors.primary;
    if (gray) color = theme.colors.gray2;
    if (active) color = theme.colors.secondary;

    return {
      backgroundColor: color,
      '&:hover': {
        backgroundColor: color,
      },
    };
  }
);

export default CustomButton;
