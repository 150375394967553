import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import DataTable from './components/DataTable';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/data-table" element={<DataTable />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

export default AppRoutes;
