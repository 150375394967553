import React from 'react';
import {
  Box,
  Modal,
  Theme,
  Button,
  Container,
  Typography,
  IconButton,
} from '@mui/material';
import {
  Line,
  XAxis,
  YAxis,
  Label,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as htmlToSvg from 'html-to-image';

import { BUCKET_URL, getXAxisLabel } from '../../utils/constants';

interface Props {
  open: boolean;
  chartData: any;
  setOpen: (flag: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: '30px',
    margin: '20px',
    padding: '50px !important',
    position: 'relative',
    maxWidth: '1100px',
    maxHeight: '770px',
    '& .closeButton': {
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
    '& .chartHeaderWrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .chartTitle': {
        fontSize: '38px',
        fontWeight: 'bold',
        lineHeight: '133.19%',
        color: theme.colors.gray,
      },
    },
    '& .kaplanMeierChartWrapper': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& .chartInfo': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '15px 0',
        paddingBottom: '40px',
        fontWeight: '500',
        '& .infoType': {
          fontSize: '16px',
          lineHeight: '141.69%',
          color: theme.colors.gray,
          fontWeight: '500',
          width: '50%',
        },
        '& .indicator': {
          display: 'flex',
          alignItems: 'center',
          '& #box': {
            display: 'flex',
            alignItems: 'center',
            '& p': {
              fontSize: '16px',
              lineHeight: '22.67px',
              margin: '0 15px 0 5px',
            },
            '& svg': {
              fontSize: '14px',
            },
          },
          '& .pData': {
            backgroundColor: '#7000FF',
            display: 'inline',
            padding: '5px 10px',
            borderRadius: '4px',
            height: '29px',
            fontSize: '12px',
            color: '#FFFFFF',
          },
        },
      },
    },
  },
  btnClass: {
    '& button': {
      backgroundColor: theme.colors.secondary,
      height: '44px',
      color: theme.colors.gray,
      width: '154px',
      textTransform: 'capitalize',
    },
  },
  wrapper: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingRight: '20px',
  },
  plotChartWrapper: {
    marginBottom: '10px',
  },
}));

function Chart(props: Props) {
  const { open, setOpen, chartData } = props;
  const handleClose = () => setOpen(false);
  let csvFilename = '';
  let csvDataType = '';
  let csvDownloadPath = '';
  if (chartData?.meta) {
    switch (chartData.meta.data_type) {
      case 'methylation':
        csvDataType = 'methylation-mean';
        break;
      default:
        csvDataType = chartData.meta.data_type;
        break;
    }
    csvFilename = `${encodeURIComponent(
      encodeURIComponent(chartData.meta.gene)
    )}.csv`;
    csvDownloadPath = `${BUCKET_URL}${csvDataType}/${chartData.meta.cancer_type}/${csvFilename}`;
  }

  const downloadCSV = () => {
    const link = window.document.createElement('a');
    link.download = csvFilename;
    link.href = csvDownloadPath;
    link.target = '_blank';
    link.click();
  };

  const downloadKMPlot = () => {
    const kmChartNode = window.document.getElementById('kaplan-meier-chart');

    if (kmChartNode) {
      htmlToSvg.toSvg(kmChartNode).then((dataUrl) => {
        const link = window.document.createElement('a');
        link.download = 'km-plot.svg';
        link.href = dataUrl;
        link.click();
      });
    }
  };

  const styles = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(78, 94, 195, 0.7)',
        },
      }}
    >
      <Container className={styles.container} maxWidth={false}>
        <IconButton className="closeButton" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box className={styles.wrapper}>
          <Box className="chartHeaderWrapper">
            <Typography className="chartTitle">Kaplan Meier Chart</Typography>
            <Box className={styles.btnClass}>
              <Button onClick={downloadCSV}>Download CSV</Button>
              <Button
                sx={{
                  marginLeft: '10px',
                }}
                onClick={downloadKMPlot}
              >
                Download KM Plot
              </Button>
            </Box>
          </Box>
          <Box id="kaplan-meier-chart" className="kaplanMeierChartWrapper">
            <Box className="chartInfo">
              <Box className="infoType">
                <div>{`Gene: ${chartData?.meta?.gene}`}</div>
                <div>{`Cancer Type: ${chartData?.meta?.cancer_type}`}</div>
                <div>{`Data Type: ${chartData?.meta?.data_type}`}</div>
              </Box>
              <Box className="indicator">
                <div id="box">
                  <FiberManualRecordIcon
                    sx={{
                      color: '#0000FF',
                    }}
                  />
                  <Typography>{chartData?.low_legend}</Typography>
                </div>
                <div id="box">
                  <FiberManualRecordIcon
                    sx={{
                      color: '#FF0000',
                    }}
                  />
                  <Typography>{chartData?.high_legend}</Typography>
                </div>
                <Box className="pData">
                  {chartData?.p < 0.0001 ? 'P < 0.0001' : `P = ${chartData?.p}`}
                </Box>
              </Box>
            </Box>
            <ResponsiveContainer className={styles.plotChartWrapper}>
              <LineChart
                margin={{
                  top: 5,
                  right: 20,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#DFE2E6" />
                <XAxis
                  fontWeight={500}
                  tickLine={false}
                  fontSize="14px"
                  stroke="rgba(0, 0, 0, 0.5)"
                  height={50}
                  type="number"
                  dataKey="time"
                  tickCount={11}
                  interval={0}
                  domain={[0, 'auto']}
                  allowDataOverflow
                  tickFormatter={(tick) => Number(tick).toLocaleString()}
                >
                  <Label
                    value={getXAxisLabel(chartData?.meta?.cancer_type)}
                    offset={0}
                    position="insideBottom"
                    style={{
                      fill: '#000000',
                      fontSize: '14px',
                    }}
                  />
                </XAxis>
                <YAxis
                  fontWeight={500}
                  tickLine={false}
                  stroke="rgba(0, 0, 0, 0.5)"
                  fontSize="14px"
                  type="number"
                  dataKey="percent"
                  tickCount={11}
                  interval={0}
                  domain={[0.0, 1.0]}
                >
                  <Label
                    value="Patient Survival"
                    angle={-90}
                    position="insideLeft"
                    style={{
                      fill: '#000000',
                      fontSize: '14px',
                    }}
                  />
                </YAxis>
                <Line
                  type="monotone"
                  strokeWidth={4}
                  data={chartData?.high}
                  dataKey="percent"
                  stroke="#FF0000"
                  dot={false}
                />
                <Line
                  type="monotone"
                  strokeWidth={4}
                  data={chartData?.low}
                  dataKey="percent"
                  stroke="#0000FF"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
}

export default Chart;
