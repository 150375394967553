import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Typography, CardContent, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ChartComponent from './ChartComponent';
import { API_URL, chartDataParams, getXAxisLabel } from '../utils/constants';
import { ChartData } from './DataTable/interfaces';

import LinkLogo from '../images/link.svg';
import LinkLogo2 from '../images/link2.svg';
import {
  SAMPLE_QUESTIONS,
  SURVIVAL_DATA,
  MORE_INFORMATION,
} from './staticData';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .aboutContainer': {
      display: 'flex',
      flexDirection: 'row',
      '& .card': {
        background:
          'linear-gradient(230.12deg, #4050B5 12.97%, #5B6BD0 89.56%)',
        color: theme.colors.white,
        borderRadius: '31px 17px 17px 31px',
        padding: '20px',
        height: '100%',
        border: 'none',

        '& .sampleQuestions': {
          display: 'flex',
          margin: '10px 0',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '25.72px',
          alignItems: 'baseline',
        },
      },
      '& .link': {
        marginLeft: '8px',
        textDecoration: 'none',
        color: '#FFFFFF',
        opacity: '0.8',
        '&:hover': {
          color: '#FFFFFF',
          textDecoration: 'underline',
        },
      },
      '& .link2': {
        color: '#3B3B3B',
        opacity: '0.8',
        marginLeft: '8px',
        textDecoration: 'none',
        '&:hover': {
          color: '#3B3B3B',
          textDecoration: 'underline',
        },
      },
    },
  },
  chartWrapper: {
    backgroundColor: '#E9E8ED',
    borderRadius: '31px',
    minHeight: '340px',
    display: 'flex',
    padding: '10px 5px',
    marginBottom: '5px',
  },
  chartContainer: {
    padding: '0 5px',
    width: '25%',
    textDecoration: 'none',
    color: theme.colors.gray1,
  },
  chart: {
    position: 'relative',
    backgroundColor: theme.colors.white,
    borderRadius: '32px',
    padding: '15px',
    height: '100%',
  },
}));

function Home() {
  const styles = useStyles();
  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    const chartApiCalls = chartDataParams.map((param) => {
      const url = `${API_URL}/chartdata?${stringify(param)}`;

      return fetch(url).then((res: Response) => res.json());
    });

    Promise.all(chartApiCalls).then(setChartData).catch(console.error);
  }, []);

  return (
    <Box className={styles.container}>
      <Box className={styles.chartWrapper}>
        {chartData.map((data: any, idx) => (
          <Link
            to={chartDataParams[idx].link}
            className={styles.chartContainer}
          >
            <div className={styles.chart}>
              <ChartComponent
                title={chartDataParams[idx].title}
                XaxisLabel={getXAxisLabel(chartDataParams[idx].cancer_type)}
                YaxisLabel={chartDataParams[idx].yAxis}
                highLegend={chartDataParams[idx].highLegend || ''}
                lowLegend={chartDataParams[idx].lowLegend || ''}
                data={data}
              />
            </div>
          </Link>
        ))}
      </Box>
      <Box className="aboutContainer">
        <Box sx={{ minWidth: 275, width: '50%' }}>
          <Card variant="outlined" className="card">
            <CardContent>
              <Typography
                sx={{
                  fontSize: '38px',
                  lineHeight: '45px',
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                About this tool
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '139.69%',
                    marginBottom: '40px',
                  }}
                >
                  Genetic, epigenetic, and transcriptional patterns that are
                  present in a primary tumor can predict the likelihood of
                  cancer patient relapse and death. This website provides access
                  to a comprehensive analysis of mutations, copy number
                  alterations, methylation, microRNA, mRNA, and protein
                  expression patterns linked with cancer outcome in{' '}
                  <a
                    style={{
                      color: '#FFFFFF',
                    }}
                    href="https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Cancer Genome Atlas
                  </a>
                  .
                </Typography>

                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '139.69%',
                    marginBottom: '50px',
                  }}
                >
                  Figures reported here correspond to Z scores calculated from
                  Cox univariate hazards models regressing a gene or genetic
                  feature against patient outcome in the indicated cancer type.
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '22px',
                  lineHeight: '139.69%',
                  fontWeight: '500',
                }}
              >
                Sample questions this resource can address
              </Typography>
              {SAMPLE_QUESTIONS.map((question) => (
                <div key={question.link} className="sampleQuestions">
                  <img src={LinkLogo} alt="Link" />
                  <a
                    className="link"
                    href={question.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {question.title}
                  </a>
                </div>
              ))}
              <div
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '25.72px',
                  marginTop: '20px',
                }}
              >
                ... and many more
              </div>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ minWidth: 275, width: '50%', marginLeft: '5px' }}>
          <Card
            variant="outlined"
            sx={{
              background: 'linear-gradient(0deg, #FFC10B, #FFC10B), #F1ECAA',
              borderRadius: '17px 31px 31px 17px',
              padding: '20px',
              height: '100%',
              border: 'none',
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  color: '#3B3B3B',
                  fontSize: '38px',
                  lineHeight: '45px',
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                Data
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '139.69%',
                  fontWeight: 500,
                  color: '#3B3B3B',
                }}
              >
                Complete tables of survival data for each genomic platform can
                be downloaded below:
              </Typography>

              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '50px',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '183.69%',
                  color: '#3B3B3B',
                  opacity: '0.8',
                  alignItems: 'baseline',
                }}
              >
                {SURVIVAL_DATA.map((data) => (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <img src={LinkLogo2} alt="Link" />
                    <a
                      className="link2"
                      href={data.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.title}
                    </a>
                  </div>
                ))}
              </div>

              <Typography
                sx={{
                  margin: '15px 0',
                  fontWeight: '500',
                  fontSize: '22px',
                  lineHeight: '139.69%',
                  color: '#3B3B3B',
                }}
              >
                More information about this analysis can be found in:
              </Typography>
              {MORE_INFORMATION.map((data) => (
                <div
                  style={{
                    display: 'flex',
                    margin: '10px 0',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '163.19%',
                    color: '#3B3B3B',
                    opacity: '0.8',
                    alignItems: 'baseline',
                  }}
                >
                  <img src={LinkLogo2} alt="Link" />
                  <a
                    className="link2"
                    href={data.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.title}
                  </a>
                </div>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
