import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function MobileMessage() {
  if (window.innerWidth >= 768) return null;
  return (
    <Modal
      open
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '700px',
        width: '100vw',
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(78, 94, 195)',
        },
      }}
    >
      <Box sx={style}>
        <Typography>
          This resource is optimized for use on desktop browsers.
        </Typography>
      </Box>
    </Modal>
  );
}

export default MobileMessage;
