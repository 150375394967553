import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Button from './Button';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 250px 250px',
    gridGap: '5px',
    marginTop: '15px',
    marginBottom: '5px',
    height: '61px',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    background: theme.colors.white,
    borderRadius: '10px',
    paddingLeft: '42px',
  },
  appName: {
    color: theme.colors.gray,
    fontSize: '22px',
    lineHeight: '26px',
    fontWeight: '500',
    textDecoration: 'none',
  },
}));

interface Props {
  setFaqOpen: (flag: boolean) => void;
}

function Header(props: Props) {
  const { setFaqOpen } = props;
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToDataTable = () => {
    if (location.pathname !== '/data-table') {
      navigate('/data-table?view=gene');
    }
  };

  return (
    <Box className={styles.headerContainer}>
      <Box className={styles.searchBar}>
        <Link className={styles.appName} to="/">
          Genetic determinants of cancer patient outcome
        </Link>
      </Box>
      <Button onClick={navigateToDataTable} active>
        Survival Data
      </Button>
      <Button onClick={() => setFaqOpen(true)}>FAQ</Button>
    </Box>
  );
}

export default Header;
