import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  Label,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  title: string;
  YaxisLabel: string;
  XaxisLabel: string;
  data: any;
  highLegend: string;
  lowLegend: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  infoWrapper: {
    height: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '21.09px',
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.gray,
  },
  indicatorInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: '0px -17px',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.colors.gray2,
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  lowIndicator: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    '&:before': {
      content: '"•"',
      fontSize: '25px',
      color: '#0000FF',
    },
  },
  highIndicator: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    '&:before': {
      content: '"•"',
      fontSize: '25px',
      color: '#FF0000',
    },
  },
  YaxisStyle: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    fontSize: '12px',
    top: '50%',
    left: '-30px',
    color: '#C1C7D0',
  },
}));

function ChartComponent(props: Props) {
  const { title, YaxisLabel, XaxisLabel, highLegend, lowLegend, data } = props;

  const styles = useStyles();

  const { high_legend: hLegend, low_legend: lLegend, high, low, p } = data;

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.indicatorInfo}>
          <div className={styles.lowIndicator}>
            &nbsp;
            {lowLegend || lLegend}
          </div>
          <div className={styles.highIndicator}>
            &nbsp;
            {highLegend || hLegend}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
            }}
          >
            {p < 0.0001 ? 'P < 0.0001' : `P = ${p}`}
          </div>
        </div>
      </div>
      <div className={styles.YaxisStyle}>{YaxisLabel}</div>
      <ResponsiveContainer height="70%">
        <LineChart
          margin={{
            top: 5,
            right: 15,
            bottom: 5,
            left: -20,
          }}
        >
          <CartesianGrid stroke="#DFE2E6" strokeDasharray="4" />
          <XAxis
            tickLine={false}
            stroke="#8DA1AB"
            fontSize="12px"
            height={35}
            type="number"
            dataKey="time"
            tickCount={4}
            interval={0}
            domain={[0, 'auto']}
          >
            <Label
              value={XaxisLabel}
              offset={0}
              position="insideBottom"
              style={{
                fill: '#C1C7D0',
                fontSize: '12px',
              }}
            />
          </XAxis>
          <YAxis
            tickLine={false}
            fontSize="12px"
            stroke="#8DA1AB"
            type="number"
            dataKey="percent"
            tickCount={3}
            interval={0}
            domain={[0.0, 1.0]}
          />
          <Line
            strokeWidth={2}
            data={high}
            dataKey="percent"
            stroke="#FF0000"
            dot={false}
          />
          <Line
            strokeWidth={2}
            data={low}
            dataKey="percent"
            stroke="#0000FF"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartComponent;
