import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Popper,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Theme,
} from '@mui/material';
import { Option } from './DataTable/interfaces';
import { useSearchOptions } from '../hooks/useSearchOptions';
import { getCancerTypeStandsFor } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: '10px',
  },
  root: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      padding: '1px 2px',
      margin: '5px 0',
    },
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.gray3,
        borderRadius: '20px',
        boxShadow: 'inset 0 0 10px 10px red',
        border: 'solid 3px transparent',
      },
      maxHeight: '21.3rem',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '400',
      paddingRight: '2px',
      color: theme.colors.gray,
      '& li': {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
  },
  textField: {
    '& .MuiInputBase-input.MuiAutocomplete-input': {
      color: theme.colors.gray,
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'normal',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  label: {
    color: '#6C6C6C',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14.06px',
    marginBottom: '10px',
  },
}));

function CustomPopper(props: any) {
  const styles = useStyles();
  return <Popper {...props} className={styles.root} placement="bottom" />;
}

interface AutoCompleteProps {
  label: string;
  parimaryType: string;
  value: string;
  setValue: (type: string) => void;
  dataSource: string;
  setDataSource?: (type: string) => void;
  disabled: boolean;
}

function CustomAutoComplete(props: AutoCompleteProps) {
  const styles = useStyles();
  const {
    label,
    value,
    setValue,
    parimaryType,
    dataSource,
    setDataSource,
    disabled,
  } = props;
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState<string | Option>(
    parimaryType === dataSource ? value : { value, source: dataSource }
  );
  const { options, loading } = useSearchOptions(
    parimaryType,
    search,
    parimaryType === dataSource
  );

  useEffect(() => {
    setSearch(value);
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
      setSelectedOption('');
    }
  }, [value, parimaryType]);

  const onChange = (option: string | Option) => {
    setSelectedOption(option);
    if (typeof option !== 'string') {
      setValue(option.value);
      if (setDataSource) setDataSource(option.source);
    }
    if (typeof option === 'string') setValue(option);
  };

  const getOptionLabel = (option: string | Option) => {
    if (typeof option !== 'string') {
      if (option.source === 'cancer_type')
        return getCancerTypeStandsFor(option.value);
      return option.value || '';
    }
    return option;
  };

  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.label}>{label}</Typography>
      <Autocomplete
        value={selectedOption}
        PopperComponent={CustomPopper}
        options={options}
        getOptionLabel={getOptionLabel}
        loading={loading}
        onChange={(event, value) => onChange(value || '')}
        onInputChange={(event, value) => setSearch(value || '')}
        renderInput={(params) => (
          <TextField
            {...params}
            className={styles.textField}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        disabled={disabled}
      />
    </Box>
  );
}

export default CustomAutoComplete;
