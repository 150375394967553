import React from 'react';
import Box from '@mui/material/Box';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export const bgColor = (val: number | string) => {
  let color = '#4785ff'; // most intense negative color
  if (val !== 0.0 && (!val || val === '')) {
    color = '#FFFFFF';
  } else if (val > 5.0) {
    color = 'rgba(255, 194, 10, 1)';
  } else if (val > 4.0) {
    color = 'rgba(255, 194, 10, 1)';
  } else if (val > 3.0) {
    color = 'rgba(255, 194, 10, 0.8)';
  } else if (val > 2.0) {
    color = 'rgba(255, 194, 10, 0.6)';
  } else if (val > 1.0) {
    color = 'rgba(255, 194, 10, 0.4)';
  } else if (val > 0.0) {
    color = 'rgba(233, 232, 232, 1)';
  } else if (val === 0.0) {
    color = '#EEEEEE';
  } else if (val < 0.0 && val >= -1.0) {
    color = 'rgba(74, 90, 191, 0.2)';
  } else if (val < -1.0 && val >= -2.0) {
    color = 'rgba(74, 90, 191, 0.4)';
  } else if (val < -2.0 && val >= -3.0) {
    color = 'rgba(74, 90, 191, 0.6)';
  } else if (val < -3.0 && val >= -4.0) {
    color = 'rgba(119, 133, 228, 0.8)';
  } else if (val < -4.0 && val >= -5.0) {
    color = 'rgba(119, 133, 228, 0.9)';
  } else if (val < -5.0) {
    color = 'rgba(119, 133, 228, 0.9)';
  }

  return { 'background-color': color, height: '30px', 'line-height': '30px' };
};

const renderCellBgColor = (params: any) => {
  const defaultPointer = params.field === 'stouffers';

  return (
    <Box
      sx={{
        backgroundColor: bgColor(params.value)['background-color'],
        flex: 1,
        margin: '-0.544rem',
        height: '26px',
        lineHeight: '26px',
        cursor: defaultPointer ? 'default' : 'pointer',
        textAlign: 'center',
        borderRadius: '2px',
        color: '#333333',
        fontSize: '12px',
        fontWeight: '500',
      }}
    >
      {params.value}
    </Box>
  );
};

function FirstColumnCell({
  params,
  sortString,
}: {
  params: any,
  sortString: string,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>{params.value}</span>
      {sortString.includes('asc') && sortString.includes(params.value) && (
        <ArrowRightIcon />
      )}
      {sortString.includes('desc') && sortString.includes(params.value) && (
        <ArrowLeftIcon />
      )}
    </Box>
  );
}

export const structureData = (data: any, sortString: string) => {
  let stouffersIdx;
  let firstColumnIdx;

  const renderFirstCell = (params: any) => (
    <FirstColumnCell params={params} sortString={sortString} />
  );

  const columns = data.schema.fields
    .filter((c: any) => c.name !== 'index')
    .map((c: any, idx: number) => {
      if (c.name === 'gene') {
        firstColumnIdx = idx;
        return {
          headerName: 'Gene',
          headerAlign: 'left',
          field: c.name,
          width: 150,
          renderCell: renderFirstCell,
        };
      }

      if (c.name === 'data_type') {
        firstColumnIdx = idx;
        return {
          headerName: 'Data Type',
          field: c.name,
          renderCell: renderFirstCell,
        };
      }

      if (c.name === 'stouffers') {
        stouffersIdx = idx;
        return {
          headerName: 'Pan-Cancer',
          field: c.name,
          renderCell: renderCellBgColor,
          width: 80,
        };
      }

      return {
        headerName: c.name,
        field: c.name,
        renderCell: renderCellBgColor,
        width: 80,
      };
    });

  if (firstColumnIdx && firstColumnIdx !== -1) {
    const firstCol = columns[firstColumnIdx];
    columns.splice(firstColumnIdx, 1);
    columns.splice(0, 0, firstCol);
  }

  if (stouffersIdx && stouffersIdx !== -1) {
    const idx = columns.findIndex((col: any) => col.field === 'stouffers');

    if (idx !== 1) {
      const panCanIdx = columns[idx];
      columns.splice(idx, 1);
      columns.splice(1, 0, panCanIdx);
    }
  }

  return {
    data: data.data.map((d: any, i: number) => ({
      ...d,
      id: i,
    })),
    schema: {
      fields: columns,
    },
  };
};
