export const FAQs = [
  {
    title: 'Where does the cancer genomic data come from?',
    body: 'This analysis was conducted using data from The Cancer Genome Atlas (TCGA), which was downloaded from the <a style="text-decoration: none;" href="https://gdc.cancer.gov/node/905/" target="_blank">NCI Genomic Data Commons</a>.',
  },
  {
    title: 'What was updated for version 2.0?',
    body: 'All genomic data was updated based on the <a style="text-decoration: none;" href="https://gdc.cancer.gov/node/905/" target="_blank">final TCGA Atlas publications</a>. Survival data was updated based on the new clinical annotations provided in <a style="text-decoration: none;" href="https://www.sciencedirect.com/science/article/pii/S0092867418302290" target="_blank">Liu et al</a>. Additionally, we expanded the analysis to cover all 33 cancer types profiled by TCGA.',
  },
  {
    title: 'What types of genomic data are included in this analysis?',
    body: 'This analysis includes data from reverse-phase protein arrays (RPPA), RNA-Seq (rnaseq), microRNA-Seq (microRNAs), copy number as determined by SNP arrays (CNAs), methylation as judged by methylation arrays (methylation), and whole-genome/whole-exome sequencing (mutations).',
  },
  {
    title: 'Is the code to perform the survival analysis publicly available?',
    body: 'Yes. The code can be found <a style="text-decoration: none;" href="https://github.com/joan-smith/comprehensive-tcga-survival" target="_blank">here</a>.',
  },
  {
    title: 'Where can I find the complete TCGA survival analysis?',
    body: 'The complete analysis is available <a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/Table%20S1%20-%20univariate%20Cox%20models.xlsx" target="_blank">here</a>.',
  },
  {
    title: 'How was this analysis conducted?',
    body:
      'Cox proportional hazards models were generated linking the expression, copy number, methylation, or mutation status of every gene or microRNA in the genome with patient outcome in 16 different cancer types profiled by the TCGA.<br/><br/>' +
      'Cox proportional hazards models posit that patients in a population have an underlying risk of dying at any particular time that can be denoted by the hazard function.  Certain features can increase the hazard function (implying a greater risk of death per unit time) or decrease the hazard function (implying a lower risk of death per unit time).  Cox proportional hazards models perform a regression analysis to determine whether one or more covariates decrease or increase the underlying hazard function, thereby identifying good and bad prognostic factors, respectively.<br/><br/>' +
      'More information on survival analysis can be found <a style="text-decoration: none;" href="http://www.springer.com/us/book/9781441966452" target="_blank">here</a>.',
  },
  {
    title: 'What is a Z score?',
    body:
      'The Z score, also called the Wald statistic, indicates whether the coefficient of a given variable is significantly different than 0.  The Z score is calculated by dividing the regression coefficient of a variable by its standard error.  The correspondence between Z scores and P values is displayed in this graph:<br/>' +
      '<br/><img style="width: 100%;" src="/faqs/zscorevpvalue.png"/><br/>',
  },
  {
    title: 'How are the Kaplan-Meier curves generated?',
    body: 'Kaplan-Meier curves are generated by dividing patients into two groups and comparing the survival times between each group.  For RNA-Seq, microRNAs, and RPPA, the division is made based on the mean expression of the feature.  For CNAs, the division is made based on the mean copy number of the feature.  For methylation, the division is made based on the mean methylation level of the feature.  For mutations, the division is made between patients who have a mutation in a gene and patients who lack mutations in a gene.',
  },
  {
    title:
      'Why does the Z score analysis give a different result than the Kaplan-Meier curve?',
    body:
      'Cox proportional hazards models can use continuous input data.  In contrast, this data is bifurcated for the Kaplan-Meier analysis.  The bifurcation causes a loss in granularity but aids in visualization.  For instance, for the following analysis of PIK3CA copy number vs. outcome in pancreas cancer, compare the graphs of the raw input data used by the Cox model vs. the Kaplan-Meier summary curve:<br/>' +
      '<br/><img style="width: 100%" src="/faqs/kmchart.png"/><br/><br/>' +
      'Significantly more information is contained in the graph on the left, but it is almost impossible to visually detect a survival trend that is apparent in the Kaplan-Meier analysis on the right.',
  },
  {
    title: 'What types of alterations are counted as mutations?',
    body: 'All non-synonymous mutations in a gene were compiled to generate the patient populations considered to have a mutation in a particular gene.  This can include missense mutations, nonsense mutations, splice-site mutations, in-frame indels, and frameshift indels.  Intronic mutations, silent mutations, and mutations affecting the 3’ or 5’ UTR were not considered in this analysis.',
  },
  {
    title:
      'Why are some genes names in parenthesis?  What’s the difference between “BRD4” and “BRD4 (BRD4)”?',
    body: 'The reverse-phase protein arrays (RPPA) are performed using antibodies that may recognize a specific epitope or post-translational modification.  For the RPPA data, the first term is the gene or gene that encodes the epitope, while the term in parenthesis indicates the specific epitope.  For instance, “CDK1 (CDK1)” refers to measurements made using an antibody that recognizes the CDK1 protein, while “CDK1 (CDK1_pY15)” refers to measurements made using an antibody that recognizes CDK1 phoshorylated on Tyr15.',
  },
  {
    title: 'Is there a publication to cite when using this data?',
    body: 'Yes. For version 1, please cite: <a style="text-decoration: none;" href="https://elifesciences.org/articles/39217">Smith, J.C. and Sheltzer, J.M. (2018)</a>.<br/>For version 2, please cite: <a style="text-decoration: none;" href="https://www.cell.com/cell-reports/fulltext/S2211-1247(22)00313-8">Smith, J.C. and Sheltzer, J.M. (2022)</a>.',
  },
  {
    title:
      'Who can I contact if I have additional questions about this analysis?',
    body: 'Dr. Jason Sheltzer supervised the development of this resource. He can be contacted at (HisFirstName).(HisLastName)@yale.edu.',
  },
  {
    title: 'Where can I find the raw data used for the survival analysis?',
    body: 'Clicking on any individual Z score will bring up a Kaplan-Meier plot displaying the patient survival data in graphical form.  On that page, select “Download CSV” to download the patient data used for that analysis.',
  },
  {
    title:
      'Where can I find multivariate survival analysis that incorporates patient age, tumor stage, etc.?',
    body: 'Multivariate survival models are described in <a style="text-decoration: none;" href="https://www.cell.com/cell-reports/fulltext/S2211-1247(22)00313-8">Smith, J.C. and Sheltzer, J.M. (2022)</a>. Note that overall, Z scores calculated from univariate and multivariate models were highly concordant (R > 0.9). Z scores from the multivariate models can be downloaded at the following links:<br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/Multivariate%20-%20CNA.xlsx">Multivariate - CNA Z scores</a><br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/Multivariate%20-%20gene%20expression.xlsx">Multivariate - Gene expression Z</a><br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/multivariate%20-%20miRNA%20expression.xlsx">Multivariate - miRNA expression Z scores</a><br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/Multivariate%20-%20mutations.xlsx">Multivariate - mutation Z scores</a><br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/Multivariate%20-%20methylation.xlsx">Multivariate - methylation Z scores</a><br/><a style="text-decoration: none;" href="https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/multivariate/multivariate%20-%20protein%20expression.xlsx">Multivariate - protein expression Z scores</a><br/>',
  },
];

export const NEW_VERSION_INFO = [
  {
    title:
      'Survival analysis has been expanded to include all 10,884 patients and 33 cancer types from TCGA.',
  },
  {
    title:
      'Clinical data has been updated according to the harmonized data reported in <a style="text-decoration: none;" href="https://www.sciencedirect.com/science/article/pii/S0092867418302290" target="_blank"> Liu et al. </a>',
  },
  {
    title:
      'Genomic data has been updated based on the final consensus <a style="text-decoration: none;" href="https://gdc.cancer.gov/about-data/publications/pancanatlas" target="_blank">PanCanAtlas publications.</a>',
  },
  {
    title:
      'Outcome endpoints have been selected based on the recommendations provided by <a style="text-decoration: none;" href="https://www.sciencedirect.com/science/article/pii/S0092867418302290" target="_blank">Liu et al.</a>',
  },
];

export const SAMPLE_QUESTIONS = [
  {
    title:
      'In what cancers types are mutations in TP53 associated with prognosis?',
    link: '/data-table?view=gene&filter=data_type&gene=TP53&data_type=mutation&sort=row,mutation,desc',
  },
  {
    title: 'Is PIK3CA copy number a prognostic biomarker in colon cancer?',
    link: '/data-table?view=gene&gene=PIK3CA&filter=cancer_type&cancer_type=COAD',
  },
  {
    title:
      'What microRNAs are associated with poor prognosis in lung adenocarcinoma?',
    link: '/data-table?view=cancer_type&cancer_type=LUAD&sort=col,microRNAs,desc',
  },
  {
    title:
      'Which methylation sites show the strongest pan-cancer association with patient survival?',
    link: '/data-table?view=data_type&data_type=methylation&sort=col,Pan-Cancer,desc',
  },
];

export const SURVIVAL_DATA = [
  {
    title: 'Copy number alteration Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/CNAs.xlsx',
  },
  {
    title: 'DNA methylation Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/Methylation.xlsx',
  },
  {
    title: 'Gene expression Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/Gene%20expression.xlsx',
  },
  {
    title: 'miRNA expression Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/miRNA%20expression.xlsx',
  },
  {
    title: 'Mutation Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/Mutations.xlsx',
  },
  {
    title: 'Protein expression Z scores',
    link: 'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/full-downloads/Protein%20expression.xlsx',
  },
];

export const MORE_INFORMATION = [
  {
    title:
      'Version 1: Smith, J.C., and Sheltzer, J.M. (2018). Systematic identification of mutations and copy number alterations associated with cancer patient prognosis. eLife. e39217.',
    link: 'https://elifesciences.org/articles/39217',
  },
  {
    title:
      'Version 2: Smith, J.C., and Sheltzer, J.M. (2022). Genome-wide identification and analysis of prognostic features in human cancers. Cell Reports. doi: https://doi.org/10.1016/j.celrep.2022.110569.',
    link: 'https://www.cell.com/cell-reports/fulltext/S2211-1247(22)00313-8',
  },
];
