import { useState, useEffect } from 'react';
import { API_URL } from '../utils/constants';
import { structureData } from '../components/DataTable/functions';

export interface TableDataHookRes {
  dataset: any;
  loading: boolean;
  count: number;
}

export function useTableData(
  primarySearchType: string,
  primarySearch: string,
  secondarySearchType: string,
  secondarySearch: string,
  sortString: string,
  page: number
): TableDataHookRes {
  const [dataset, setDataset] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!primarySearchType || !primarySearch) {
      setDataset({});
      return;
    }
    const abortController = new AbortController();

    setLoading(true);

    // Set fetch url for /tabledata API
    const url = new URL(`${API_URL}/tabledata`);
    url.searchParams.append('view', primarySearchType);
    url.searchParams.append(primarySearchType, primarySearch);
    url.searchParams.append('dataset', '2021');
    if (secondarySearchType && secondarySearch) {
      url.searchParams.append('filter', secondarySearchType);
      url.searchParams.append(secondarySearchType, secondarySearch);
    }

    // Set Sort Search Params
    if (sortString) url.searchParams.append('sort', sortString);
    if (page > 0) url.searchParams.append('offset', `${page * 25}`);
    fetch(url.href, {
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setDataset(structureData(data, sortString));
        setCount(data.total_rows);
      });

    return function cancel() {
      setLoading(false);
      abortController.abort();
    };
  }, [
    primarySearchType,
    primarySearch,
    secondarySearchType,
    secondarySearch,
    sortString,
    page,
  ]);

  return { dataset, loading, count };
}
