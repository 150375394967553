import React from 'react';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { FAQs, NEW_VERSION_INFO } from './staticData';

interface Props {
  open: boolean;
  setOpen: (flag: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    '& .summary': {
      paddingLeft: '0',
      paddingRight: '0',
      '& .question': {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '21.31px',
        color: theme.colors.gray,
      },
    },
    '& .details': {
      paddingLeft: '0',
      '& .answer': {
        fontSize: '14px',
        lineHeight: '18.65px',
        color: theme.colors.gray,
      },
    },
  },

  divider: {
    color: '#EDEDED',
  },
  customScrollBar: {
    paddingRight: '25px',
    height: '400px',
    maxHeight: '500px',
    overflowY: 'scroll',
    marginTop: '15px',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E6E6F0',
      borderRadius: '20px',
    },
  },
  mainContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '30px',
    margin: '20px',
    position: 'relative',
    '& .closeButton': {
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
    '& .faqWrapper': {
      display: 'flex',
      padding: '40px',
      '& .leftContainer': {
        width: '50%',
        marginTop: '15px',
        paddingLeft: '0',
        '& .title': {
          fontWeight: '700',
          fontSize: '38px',
          lineHeight: '50.61px',
          color: '#434040',
        },
      },
      '& .rightContainer': {
        width: '50%',
        marginTop: '15px',
        paddingLeft: '40px',
        '& .wrapper': {
          backgroundColor: '#E9E8ED',
          borderRadius: '31px',
          padding: '40px',
          '& .title': {
            fontSize: '26px',
            fontWeight: '700',
            lineHeight: '34.63px',
            color: '#434040',
          },
        },
      },
    },
  },
}));

function FAQ(props: Props) {
  const styles = useStyles();
  const { open, setOpen } = props;
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '700px',
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(78, 94, 195, 0.7)',
        },
      }}
    >
      <Container className={styles.mainContainer}>
        <IconButton className="closeButton" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box className="faqWrapper">
          <Box className="leftContainer">
            <Typography className="title">FAQ</Typography>
            <Box className={styles.customScrollBar}>
              {FAQs.map((faq) => (
                <Accordion
                  key={faq.title}
                  elevation={0}
                  className={styles.accordion}
                  id="111"
                  disableGutters
                >
                  <AccordionSummary
                    className="summary"
                    expandIcon={
                      <AddIcon
                        sx={{
                          color: '#4050B5',
                        }}
                      />
                    }
                  >
                    <Typography className="question">{faq.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="details">
                    <Typography
                      className="answer"
                      dangerouslySetInnerHTML={{
                        __html: `${faq.body}`,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
          <Box className="rightContainer">
            <div className="wrapper">
              <Typography className="title">
                December 2021: New for version 2.0
              </Typography>
              {NEW_VERSION_INFO.map((nvI) => (
                <div
                  key={nvI.title}
                  style={{
                    display: 'flex',
                    margin: '10px 0',
                    fontSize: '16px',
                    color: '#434040',
                    lineHeight: '21.31px',
                  }}
                >
                  <div
                    style={{
                      marginRight: '8px',
                    }}
                  >
                    —
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: nvI.title,
                    }}
                  />
                </div>
              ))}
            </div>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
}

export default FAQ;
