import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  // eslint-disable-next-line
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    xl: false;
  }
  // eslint-disable-next-line
  interface Theme {
    colors: {
      primary: string;
      secondary: string;
      white: string;
      black: string;
      gray: string;
      gray1: string;
      gray2: string;
      gray3: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: {
      primary: string;
      secondary: string;
      white: string;
      black: string;
      gray: string;
      gray1: string;
      gray2: string;
      gray3: string;
    };
  }
}
 
const theme = createTheme({
  palette: {
    background: {
      default: '#F1F1F1',
    },
    primary: {
      main: '#4151B6'
    },
    secondary: {
      main: '#FFC10B',
    },
  },
  typography: {
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', \'sans-serif\'',
    h1: {
      fontSize: '38px',
      fontWeight: '700',
      lineHeight: '45px',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '21px',
    },
  },
  breakpoints: {
    values: {
      lg: 1432,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontWeight: 'bold',
          backgroundColor: '#4151B6',
          padding: '16px',
          borderRadius: '12px',
          fontSize: '14px',
          '&:hover': {
            backgroundColor: '#4151B6',
          },
        }
      }
    },
  },
  colors: {
    primary: '#4151B6',
    secondary: '#FFC10B',
    white: '#fff',
    black: '#000',
    gray: '#434040',
    gray1: '#332F2F',
    gray2: '#9799A4',
    gray3: '#E6E6F0',
  },
});

export default theme;
