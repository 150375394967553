import React, { useState, useEffect } from 'react';
import { Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';

import Table from './Table';
import Button from '../Button';
import AutoComplete from '../AutoComplete';
import { searchTypes, Z_SCORES } from '../../utils/constants';
import { useTableData } from '../../hooks/useTableData';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gridGap: '10px',
  },
  searchPanelContainer: {
    height: '550px',
    '& .MuiAutocomplete-root': {
      backgroundColor: theme.colors.white,
      borderRadius: '4px',
      '&:hover': {},
    },
    padding: '20px',
    backgroundColor: '#E9E8ED',
    borderRadius: '24px 17px 17px 24px',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '5px',
    margin: '10px 0',
  },
  button: {
    padding: '8px 0',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '4px',
    textTransform: 'none',
  },
  label: {
    color: '#6C6C6C',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14.06px',
    marginBottom: '10px',
  },
  zScoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    gridRrow: 2,
    gridColumn: 2,
    '& .zScore': {
      width: '21px',
      height: '26px',
      lineHeight: '26px',
      margin: '1px',
      textAlign: 'center',
      borderRadius: '2px',
      fontWeight: '500',
      fontSize: '12px',
      color: theme.colors.black,
    },
  },
}));

function DataTable() {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortString, setSortString] = useState(searchParams.get('sort') || '');
  const [page, setPage] = useState<number>(0);
  // Search Panel Props
  const [primarySearchType, setPrimarySearchType] = useState(
    searchParams.get('view') || 'gene'
  );
  const [secondarySearchType, setSecondarySearchType] = useState(
    searchParams.get('filter') || ''
  );
  const [primarySearch, setPrimarySearch] = useState(
    searchParams.get(primarySearchType) || ''
  );
  const [secondarySearch, setSecondarySearch] = useState(
    searchParams.get(secondarySearchType) || ''
  );

  const { dataset, loading, count } = useTableData(
    primarySearchType,
    primarySearch,
    secondarySearchType,
    secondarySearch,
    sortString,
    page
  );

  useEffect(() => {
    // Set query params to the /datatable page when the search criteria is changed.
    const currentParams = Object.fromEntries([...searchParams]);
    if (
      currentParams.view !== primarySearchType ||
      (currentParams[primarySearchType] || '') !== primarySearch ||
      (currentParams.filter || '') !== secondarySearchType ||
      (currentParams[secondarySearchType] || '') !== secondarySearch
    ) {
      setSearchParams({
        view: primarySearchType,
        ...(primarySearchType && primarySearch
          ? { [primarySearchType]: primarySearch }
          : {}),
        ...(secondarySearchType ? { filter: secondarySearchType } : {}),
        ...(secondarySearch ? { [secondarySearchType]: secondarySearch } : {}),
        ...(currentParams?.sort ? { sort: currentParams.sort } : {}),
      });
    }
  }, [
    primarySearchType,
    primarySearch,
    secondarySearchType,
    secondarySearch,
    sortString,
    page,
  ]);

  const handleButtonClick = (value: string) => {
    setPrimarySearchType(value);
    setPrimarySearch('');
    setSecondarySearchType('');
    setSecondarySearch('');
    setSortString('');
    const { sort, ...rest } = Object.fromEntries([...searchParams]);
    setSearchParams(rest);
  };

  const getAltSearchBoxLabel = () => {
    const altTypes = searchTypes.filter(
      ({ value }) => value !== primarySearchType
    );

    return altTypes.map(({ name }) => name.toLowerCase()).join(' or ');
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.searchPanelContainer}>
        <Typography className={styles.label}>Select filter</Typography>
        <Box className={styles.buttonWrapper}>
          {searchTypes.map(({ value, name }) => (
            <Button
              key={value}
              className={styles.button}
              onClick={() => handleButtonClick(value)}
              active={value === primarySearchType}
              gray
            >
              {name}
            </Button>
          ))}
        </Box>
        <AutoComplete
          label={`Select ${primarySearchType.replace('_', ' ')}`}
          parimaryType={primarySearchType}
          value={primarySearch}
          setValue={setPrimarySearch}
          dataSource={primarySearchType}
          disabled={loading}
        />
        <AutoComplete
          label={`Select ${getAltSearchBoxLabel()}`}
          value={secondarySearch}
          setValue={setSecondarySearch}
          parimaryType={primarySearchType}
          dataSource={secondarySearchType}
          setDataSource={setSecondarySearchType}
          disabled={loading}
        />
      </Box>
      <Table
        primaryType={primarySearchType}
        primarySearch={primarySearch}
        dataSet={dataset}
        isLoading={loading}
        sortString={sortString}
        setSortString={setSortString}
        count={count}
        setPage={setPage}
      />
      <Box className={styles.zScoreWrapper}>
        <Typography
          sx={{
            marginRight: '15px',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          Z-Score
        </Typography>
        {Z_SCORES.map((zScore) => (
          <Box
            key={zScore.color}
            className="zScore"
            sx={{
              backgroundColor: zScore.color,
            }}
          >
            {zScore.value}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default DataTable;
