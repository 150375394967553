import { useState, useEffect } from 'react';
import { API_URL } from '../utils/constants';
import { FilterByResponse, Option } from '../components/DataTable/interfaces';

export interface SearchOptionsHookRes {
  options: (string | Option)[];
  loading: boolean;
  setOptions: (options: any[]) => void;
}

const mapSecondaryFetch = (res: string[] | FilterByResponse) => {
  if (Array.isArray(res)) return res;
  return Object.entries(res).reduce((arr: Option[], entry) => {
    const [key, value] = entry;
    return [
      ...arr,
      ...value.map((val: string) => ({ source: key, value: val })),
    ];
  }, []);
};

export function useSearchOptions(
  searchType: string,
  search: string,
  isPrimary: boolean
): SearchOptionsHookRes {
  const [options, setOptions] = useState<(string | Option)[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    setLoading(true);
    setOptions([]);

    const url = isPrimary
      ? `${API_URL}/${searchType}s?dataset=2021&search=${search}`
      : `${API_URL}/filterby?view=${searchType}&search=${search}`;
    fetch(url, {
      signal: abortController.signal,
    })
      .then((res: Response) => res.json())
      .then((resOptions: string[] | FilterByResponse) => {
        setLoading(false);
        setOptions(mapSecondaryFetch(resOptions));
      });

    return function cancel() {
      abortController.abort();
    };
  }, [searchType, search]);

  return { options, setOptions, loading };
}
