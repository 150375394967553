import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Theme } from '@mui/material';

import './App.css';
import AppRoutes from './Routes';
import FAQ from './components/FAQ';
import Header from './components/Header';
import MobileMessage from './components/MobileMessage';

const useStyles = makeStyles((theme: Theme) => ({
  dataContainer: {
    background: theme.colors.white,
    padding: '32px',
    borderRadius: '31px',
    marginBottom: '20px',
  },
}));

function App() {
  const styles = useStyles();
  const [faqOpen, setFaqOpen] = useState(false);

  return (
    <Container>
      <Header setFaqOpen={setFaqOpen} />
      <Box className={styles.dataContainer}>
        <AppRoutes />
      </Box>
      <FAQ open={faqOpen} setOpen={setFaqOpen} />
      <MobileMessage />
    </Container>
  );
}

export default App;
