import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Theme, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridSortModel,
  GridSortDirection,
} from '@mui/x-data-grid';

import Chart from './Chart';
import { API_URL } from '../../utils/constants';
import Overlay from '../../images/overlay.svg';

interface Props {
  primaryType: string;
  primarySearch: string;
  dataSet: any;
  isLoading: boolean;
  sortString: string;
  setSortString: React.Dispatch<React.SetStateAction<string>>;
  count: number;
  setPage: (page: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableMainContainer: {
    height: '100%',
    padding: '43px 33px 31px 33px',
    backgroundColor: '#E9E8ED',
    borderRadius: '17px 24px 24px 17px',
    '& .MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#E6E6F0',
        borderRadius: '20px',
      },
    },
    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center',
    },
    '& .MuiDataGrid-main': {
      paddingLeft: '20px',
      marginTop: '20px',
    },
    '& .MuiDataGrid-cell--textLeft': {
      color: 'rgba(79, 78, 82, 0.5)',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '287.5%',
    },
    '& .MuiDataGrid-cell:not(.MuiDataGrid-cell--textLeft)': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-withBorder[aria-colindex="1"]': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        display: 'flex',
        padding: '0',
        justifyContent: 'flex-start',
        '& .MuiDataGrid-columnHeaderTitle': {
          fontSize: '16px',
          lineHeight: '19px',
          color: '#4F4E52',
        },
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '14.06px',
      color: 'rgba(79, 78, 82, 0.52)',
    },
    '& .MuiDataGrid-columnHeaders': {
      left: '20px',
      width: 'min-content',
    },
    '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  dataGridWrapper: {
    height: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: '8px',
  },
  spinner: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayWrapper: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    zIndex: 1,
  },
  overlayText: {
    marginTop: 8,
    fontWeight: '500',
    fontSize: '16px',
    color: theme.colors.gray,
  },
}));

function NoRowOverlayComponent() {
  const styles = useStyles();
  return (
    <Box className={styles.overlayWrapper}>
      <img src={Overlay} alt="Overlay" />
      <Typography className={styles.overlayText}>
        Select a gene, cancer type, or data type to get started!
      </Typography>
    </Box>
  );
}

function CustomLoadingOverlay() {
  const styles = useStyles();
  return (
    <Box className={styles.spinner}>
      <CircularProgress color="secondary" />
    </Box>
  );
}

const getSortModelFromUrl = (sortUrl: string): GridSortModel => {
  if (sortUrl.includes('row'))
    return [
      {
        field: '',
        sort: 'asc',
      },
    ];
  const field =
    sortUrl
      ?.split(',')
      .find((param) => !['col', 'row', 'asc', 'desc'].includes(param)) || '';
  const sort: GridSortDirection = sortUrl?.includes(',desc') ? 'desc' : 'asc';
  return [{ field: field === 'Pan-Cancer' ? 'stouffers' : field || '', sort }];
};

function Table(props: Props) {
  const {
    primaryType,
    primarySearch,
    isLoading,
    sortString,
    setSortString,
    dataSet,
    count,
    setPage,
  } = props;
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === 'stouffers') return null;
    if (['gene', 'data_type', 'cancer_type'].find((s) => s === params.field)) {
      const currentParams = Object.fromEntries([...searchParams]);
      const sort =
        sortString === `row,${params.value},asc`
          ? `row,${params.value},desc`
          : `row,${params.value},asc`;
      setSearchParams({
        ...currentParams,
        sort,
      });
      setSortString(sort);
      return null;
    }

    const colType = dataSet.schema.fields[0].field;
    const restField =
      ['gene', 'data_type', 'cancer_type'].find(
        (type) => type !== primaryType && type !== colType
      ) || 'cancer_type';
    const cellParam = {
      [primaryType]: primarySearch,
      [colType]: params.row[colType],
      [restField]: params.colDef.field,
    };
    if (cellParam) {
      const url = new URL(`${API_URL}/chartdata`);
      Object.entries(cellParam).forEach(([key, value]) =>
        url.searchParams.append(key, value)
      );
      fetch(url.href)
        .then((response) => response.json())
        .then((data) => {
          setChartData({
            ...data,
            meta: cellParam,
          });
          setOpen(true);
        })
        .catch(console.error);
    } else {
      console.error('API parameters are invalid.');
    }
  };

  const handleSortModelChange = (model: GridSortModel) => {
    const currentParams = Object.fromEntries([...searchParams]);
    const [sortInfo] = model;

    let sortField = sortInfo?.field;

    if (sortField === 'stouffers') {
      sortField = 'Pan-Cancer';
    }

    if (sortField && sortInfo?.sort) {
      setSearchParams({
        ...currentParams,
        sort: `col,${sortField},${sortInfo?.sort}`,
      });
      setSortString(`col,${sortField},${sortInfo?.sort}`);
    }
  };

  const sortModel = getSortModelFromUrl(sortString);

  return (
    <>
      <Chart open={open} setOpen={setOpen} chartData={chartData} />
      <Box className={styles.tableMainContainer}>
        <div className={styles.dataGridWrapper}>
          <DataGrid
            rows={dataSet?.data || []}
            columns={dataSet?.schema?.fields || []}
            pageSize={25}
            rowsPerPageOptions={[25]}
            showColumnRightBorder
            density="compact"
            disableColumnMenu
            onCellClick={handleCellClick}
            isRowSelectable={() => false}
            sortingOrder={['asc', 'desc']}
            hideFooter={!dataSet?.data}
            components={{
              NoRowsOverlay: NoRowOverlayComponent,
              NoResultsOverlay: NoRowOverlayComponent,
              LoadingOverlay: CustomLoadingOverlay,
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            rowCount={count}
            paginationMode="server"
            onPageChange={setPage}
            loading={isLoading}
            rowHeight={42}
          />
        </div>
      </Box>
    </>
  );
}

export default Table;
