export const API_URL =
  process.env.NODE_ENV === 'production'
    ? `${window.location.origin}/api`
    : 'https://tcga-survival.com/api';
export const BUCKET_URL =
  'https://storage.googleapis.com/cancer-survival-km-2021/cancer-survival-km/csvs/';

export const dataSetYear = {
  2018: '2018',
  2021: '2021',
};

export const chartDataParams = [
  {
    gene: 'TP53',
    cancer_type: 'LAML',
    data_type: 'mutation',
    title: 'Acute myeloid leukemia:TP53 mutations',
    yAxis: 'Fractional survival',
    highLegend: 'TP53 mutated',
    lowLegend: 'TP53 wild-type',
    link: '/data-table?view=gene&gene=TP53&filter=cancer_type&cancer_type=LAML',
  },
  {
    gene: 'RB1',
    cancer_type: 'SARC',
    data_type: 'methylation',
    title: 'Sarcoma: RB1 methylation',
    yAxis: 'Fractional survival',
    highLegend: 'High methylation',
    lowLegend: 'Low methylation',
    link: '/data-table?view=gene&gene=RB1&filter=cancer_type&cancer_type=SARC',
  },
  {
    gene: 'CYCLINB1',
    cancer_type: 'PRAD',
    data_type: 'RPPA',
    title: 'Prostate adenocarcinoma: Cyclin B1 protein expression',
    yAxis: 'Fractional survival',
    highLegend: 'High expression',
    lowLegend: 'Low expression',
    link: '/data-table?view=gene&gene=CYCLINB1&filter=cancer_type&cancer_type=PRAD',
  },
  {
    gene: 'EGFR',
    cancer_type: 'LGG',
    data_type: 'CNAs',
    title: 'Low-grade glioma: EGFR copy number alterations',
    yAxis: 'Fractional survival',
    highLegend: 'High copy number',
    lowLegend: 'Low copy number',
    link: '/data-table?view=gene&gene=EGFR&filter=cancer_type&cancer_type=LGG',
  },
];

export const Z_SCORES = [
  { value: -5, color: 'rgba(119, 133, 228, 0.9)' },
  { value: -4, color: 'rgba(119, 133, 228, 0.8)' },
  { value: -3, color: 'rgba(74, 90, 191, 0.6)' },
  { value: -2, color: 'rgba(74, 90, 191, 0.4)' },
  { value: -1, color: 'rgba(74, 90, 191, 0.2)' },
  { value: 0, color: 'rgba(233, 232, 232, 1)' },
  { value: 1, color: 'rgba(255, 194, 10, 0.2)' },
  { value: 2, color: 'rgba(255, 194, 10, 0.4)' },
  { value: 3, color: 'rgba(255, 194, 10, 0.6)' },
  { value: 4, color: 'rgba(255, 194, 10, 0.8)' },
  { value: 5, color: 'rgba(255, 194, 10, 1)' },
];

export const getCancerTypeStandsFor = (cancerType: string): string => {
  const cancerTypes: { [index: string]: string } = {
    ACC: 'Adrenocortical carcinoma',
    BLCA: 'Bladder urothelial carcinoma',
    BRCA: 'Breast invasive carcinoma',
    CESC: 'Cervical squamous cell carcinoma',
    CHOL: 'Cholangiocarcinoma',
    COAD: 'Colon adenocarcinoma',
    DLBC: 'Diffuse large B-cell lymphoma',
    ESCA: 'Esophageal carcinoma',
    GBM: 'Glioblastoma multiforme',
    HNSC: 'Head and neck squamous cell carcinoma',
    KICH: 'Kidney chromophobe',
    KIRC: 'Kidney renal clear cell carcinoma',
    KIRP: 'Kidney renal papillary cell carcinoma',
    LAML: 'Acute myeloid leukemia',
    LGG: 'Low grade glioma',
    LIHC: 'Liver hepatocellular carcinoma',
    LUAD: 'Lung adenocarcinoma',
    LUSC: 'Lung squamous cell carcinoma',
    MESO: 'Mesothelioma',
    OV: 'Ovarian serous cystadenocarcinoma',
    PAAD: 'Pancreatic adenocarcinoma',
    PCPG: 'Pheochromocytoma and paraganglioma',
    PRAD: 'Prostate adenocarcinoma',
    READ: 'Rectum adenocarcinoma',
    SARC: 'Sarcoma',
    SKCM: 'Skin cutaneous melanoma',
    STAD: 'Stomach adenocarcinoma',
    TGCT: 'Testicular germ cell tumors',
    THCA: 'Thyroid carcinoma',
    THYM: 'Thymoma',
    UCEC: 'Uterine Corpus Endometrial Carcinoma',
    UCS: 'Uterine carcinosarcoma',
    UVM: 'Uveal melanoma',
    // Available in 2018 cancer types only.
    COADREAD: 'Colon adenocarcinoma/Rectum adenocarcinoma',
    GBMLGG: 'Glioblastoma multiforme/Brain Lower Grade Glioma',
    KIPAN: 'pan-Kidney (Chromophobe/clear cell/papillary)',
  };

  return `${cancerType} (${cancerTypes[cancerType]})`;
};

export const searchTypes = [
  {
    id: 1,
    name: 'Genes',
    value: 'gene',
  },
  {
    id: 2,
    name: 'Cancer Type',
    value: 'cancer_type',
  },
  {
    id: 3,
    name: 'Data Type',
    value: 'data_type',
  },
];

export const clinicalEndpoints = [
  {
    name: 'Overall survival',
    cancerTypes: [
      'ACC',
      'BLCA',
      'CESC',
      'CHOL',
      'COAD',
      'ESCA',
      'GBM',
      'HNSC',
      'KICH',
      'KIRC',
      'KIRP',
      'LAML',
      'LIHC',
      'LUAD',
      'LUSC',
      'MESO',
      'OV',
      'PAAD',
      'SARC',
      'SKCM',
      'STAD',
      'UCEC',
      'UCS',
      'UVM',
    ],
  },
  {
    name: 'Progression free interval',
    cancerTypes: [
      'BRCA',
      'DLBC',
      'LGG',
      'PCPG',
      'PRAD',
      'READ',
      'TGCT',
      'THCA',
      'THYM',
    ],
  },
];

export const getXAxisLabel = (cancerType: string) => {
  const endpoint = clinicalEndpoints.find((endpoint) =>
    endpoint.cancerTypes.find((ct) => ct === cancerType)
  );

  if (endpoint) return `${endpoint.name} (days)`;
  return '';
};
